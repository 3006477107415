import logo from './logo.svg';
import './App.css';
import visionImpairment from "./Assets/visionImpairment.svg"
import writings from "./Assets/Writings.svg"

function App() {
  return (
    <div className="App">
      <div className="Container">
        <div className="ContainerOne">
          <div className="Writings">
              <img className="writing_svg" src={writings}/>
          </div>
          <div className="CenterContainer">
            <div className="NameBox">
                12833
            </div>
            <div className="Name">
                Reyansh Sinha
            </div>
          </div>
          <div className="Disability">
                <img className="Vision_svg" src={visionImpairment}/>
          </div>
        </div>
        <div className="ContainerTwo">
          <div className="Work">
              Works
          </div>
          <div className="FearAndFailures">
              Fear & Failures
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
